/* suisse-intl-200 - latin_latin-ext */
@font-face {
	font-display: swap;
	font-family: 'Suisse Intl';
	font-style: normal;
	font-weight: 200;
	src:
		url('/fonts/suisse/SuisseIntl-Thin.woff') format('woff2'),
		/* Chrome 36+, Opera 23+, Firefox 39+ */
			url('/fonts/suisse/SuisseIntl-Thin.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* suisse-intl-200italic - latin_latin-ext */
@font-face {
	font-display: swap;
	font-family: 'Suisse Intl';
	font-style: italic;
	font-weight: 200;
	src:
		url('/fonts/suisse/SuisseIntl-ThinItalic.woff2') format('woff2'),
		/* Chrome 36+, Opera 23+, Firefox 39+ */
			url('/fonts/suisse/SuisseIntl-ThinItalic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* suisse-intl-300 - latin_latin-ext */
@font-face {
	font-display: swap;
	font-family: 'Suisse Intl';
	font-style: normal;
	font-weight: 300;
	src:
		url('/fonts/suisse/SuisseIntl-Ultralight.woff2') format('woff2'),
		/* Chrome 36+, Opera 23+, Firefox 39+ */
			url('/fonts/suisse/SuisseIntl-Ultralight.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* suisse-intl-300italic - latin_latin-ext */
@font-face {
	font-display: swap;
	font-family: 'Suisse Intl';
	font-style: italic;
	font-weight: 300;
	src:
		url('/fonts/suisse/SuisseIntl-UltralightItalic.woff2') format('woff2'),
		/* Chrome 36+, Opera 23+, Firefox 39+ */
			url('/fonts/suisse/SuisseIntl-UltralightItalic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* suisse-intl-regular - latin_latin-ext */
@font-face {
	font-display: swap;
	font-family: 'Suisse Intl';
	font-style: normal;
	font-weight: 400;
	src:
		url('/fonts/suisse/SuisseIntl-Regular.woff2') format('woff2'),
		/* Chrome 36+, Opera 23+, Firefox 39+ */
			url('/fonts/suisse/SuisseIntl-Regular.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* suisse-intl-italic - latin_latin-ext */
@font-face {
	font-display: swap;
	font-family: 'Suisse Intl';
	font-style: italic;
	font-weight: 400;
	src:
		url('/fonts/suisse/SuisseIntl-RegularItalic.woff2') format('woff2'),
		/* Chrome 36+, Opera 23+, Firefox 39+ */
			url('/fonts/suisse/SuisseIntl-Regular.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* suisse-intl-600 - latin_latin-ext */
@font-face {
	font-display: swap;
	font-family: 'Suisse Intl';
	font-style: normal;
	font-weight: 600;
	src:
		url('/fonts/suisse/SuisseIntl-SemiBold.woff2') format('woff2'),
		/* Chrome 36+, Opera 23+, Firefox 39+ */
			url('/fonts/suisse/SuisseIntl-SemiBold.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* suisse-intl-600italic - latin_latin-ext */
@font-face {
	font-display: swap;
	font-family: 'Suisse Intl';
	font-style: italic;
	font-weight: 600;
	src:
		url('/fonts/suisse/SuisseIntl-SemiBoldItalic.woff2') format('woff2'),
		/* Chrome 36+, Opera 23+, Firefox 39+ */
			url('/fonts/suisse/SuisseIntl-SemiBoldItalic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* suisse-intl-700 - latin_latin-ext */
@font-face {
	font-display: swap;
	font-family: 'Suisse Intl';
	font-style: normal;
	font-weight: 700;
	src:
		url('/fonts/suisse/SuisseIntl-Bold.woff2') format('woff2'),
		/* Chrome 36+, Opera 23+, Firefox 39+ */
			url('/fonts/suisse/SuisseIntl-Bold.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* suisse-intl-700italic - latin_latin-ext */
@font-face {
	font-display: swap;
	font-family: 'Suisse Intl';
	font-style: italic;
	font-weight: 700;
	src:
		url('/fonts/suisse/SuisseIntl-BoldItalic.woff2') format('woff2'),
		/* Chrome 36+, Opera 23+, Firefox 39+ */
			url('/fonts/suisse/SuisseIntl-BoldItalic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* suisse-intl-900 - latin_latin-ext */
@font-face {
	font-display: swap;
	font-family: 'Suisse Intl';
	font-style: normal;
	font-weight: 900;
	src:
		url('/fonts/suisse/SuisseIntl-Black.woff2') format('woff2'),
		/* Chrome 36+, Opera 23+, Firefox 39+ */
			url('/fonts/suisse/SuisseIntl-Black.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* suisse-intl-900italic - latin_latin-ext */
@font-face {
	font-display: swap;
	font-family: 'Suisse Intl';
	font-style: italic;
	font-weight: 900;
	src:
		url('/fonts/suisse/SuisseIntl-BlackItalic.woff2') format('woff2'),
		/* Chrome 36+, Opera 23+, Firefox 39+ */
			url('/fonts/suisse/SuisseIntl-BlackItalic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
	font-family: 'SuisseIntl Fallback';
	font-style: normal;
	font-weight: 200;
	src: local('Arial');
	ascent-override: 103.02%;
	descent-override: 35.97%;
	line-gap-override: 0%;
	size-adjust: 98.13%;
}
@font-face {
	font-family: 'SuisseIntl Fallback';
	font-style: italic;
	font-weight: 200;
	src: local('Arial Italic');
	ascent-override: 103.02%;
	descent-override: 35.97%;
	line-gap-override: 0%;
	size-adjust: 98.13%;
}
@font-face {
	font-family: 'SuisseIntl Fallback';
	font-style: normal;
	font-weight: 300;
	src: local('Arial');
	ascent-override: 101.29%;
	descent-override: 35.37%;
	line-gap-override: 0%;
	size-adjust: 99.82%;
}
@font-face {
	font-family: 'SuisseIntl Fallback';
	font-style: italic;
	font-weight: 300;
	src: local('Arial Italic');
	ascent-override: 101.27%;
	descent-override: 35.36%;
	line-gap-override: 0%;
	size-adjust: 99.84%;
}
@font-face {
	font-family: 'SuisseIntl Fallback';
	font-style: normal;
	font-weight: 400;
	src: local('Arial');
	ascent-override: 99.49%;
	descent-override: 34.74%;
	line-gap-override: 0%;
	size-adjust: 101.62%;
}
@font-face {
	font-family: 'SuisseIntl Fallback';
	font-style: italic;
	font-weight: 400;
	src: local('Arial Italic');
	ascent-override: 99.49%;
	descent-override: 34.74%;
	line-gap-override: 0%;
	size-adjust: 101.62%;
}
@font-face {
	font-family: 'SuisseIntl Fallback';
	font-style: normal;
	font-weight: 600;
	src: local('Arial Bold');
	ascent-override: 105.78%;
	descent-override: 36.94%;
	line-gap-override: 0%;
	size-adjust: 95.57%;
}
@font-face {
	font-family: 'SuisseIntl Fallback';
	font-style: italic;
	font-weight: 600;
	src: local('Arial Bold Italic');
	ascent-override: 105.78%;
	descent-override: 36.94%;
	line-gap-override: 0%;
	size-adjust: 95.57%;
}
@font-face {
	font-family: 'SuisseIntl Fallback';
	font-style: normal;
	font-weight: 700;
	src: local('Arial Bold');
	ascent-override: 103.63%;
	descent-override: 36.18%;
	line-gap-override: 0%;
	size-adjust: 97.56%;
}
@font-face {
	font-family: 'SuisseIntl Fallback';
	font-style: italic;
	font-weight: 700;
	src: local('Arial Bold Italic');
	ascent-override: 103.63%;
	descent-override: 36.18%;
	line-gap-override: 0%;
	size-adjust: 97.56%;
}
@font-face {
	font-family: 'SuisseIntl Fallback';
	font-style: normal;
	font-weight: 800;
	src: local('Arial Bold');
	ascent-override: 101.35%;
	descent-override: 35.39%;
	line-gap-override: 0%;
	size-adjust: 99.75%;
}
@font-face {
	font-family: 'SuisseIntl Fallback';
	font-style: italic;
	font-weight: 800;
	src: local('Arial Bold Italic');
	ascent-override: 101.35%;
	descent-override: 35.39%;
	line-gap-override: 0%;
	size-adjust: 99.75%;
}
@font-face {
	font-family: 'SuisseIntl Fallback';
	font-style: normal;
	font-weight: 900;
	src: local('Arial Bold');
	ascent-override: 99.1%;
	descent-override: 34.6%;
	line-gap-override: 0%;
	size-adjust: 102.02%;
}
@font-face {
	font-family: 'SuisseIntl Fallback';
	font-style: italic;
	font-weight: 900;
	src: local('Arial Bold Italic');
	ascent-override: 99.1%;
	descent-override: 34.6%;
	line-gap-override: 0%;
	size-adjust: 102.02%;
}
